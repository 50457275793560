import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { PlusSmIcon } from '@heroicons/react/solid'


import SEO from "../components/common/seo"
import Layout from "../components/common/layout"
import CommunitiesTable from "../components/community/communitiestable"
import TopicList from "../components/topic/topiclist"
import TopicStats from "../components/topic/stats"
import TopicFilters from "../components/topic/filters"
import TopicPlatformIcons from "../components/topic/topicPlatformIcons";
import RelatedTopics from "../components/topic/relatedTopics";
import PromotedTopicCommunities from "../components/community/promotedtopiccommunities"
// import Adsense from "../components/promotions/adsense"
import Deals from "../components/promotions/deals";

import { sortCommunities } from '../utils/communities'



const PlatformTopicPage = ({ data, classes, pageContext }) => {
  const topic = data.topic;
  const platform = data.platform;
  const communities = data.communities.nodes;
  const relatedTopics = data.relatedTopics.nodes;
  const deals = data.deals.nodes;
  
  const platformCommunities = communities.filter(c => c.platform.split(',').includes(pageContext.platformSlug));
  const count = platformCommunities.length;
  const otherCommunities = communities.filter(c => c.platform !== pageContext.platformSlug);
  // for now promote on non-platform pages too
  // const promotedCommunities = platformCommunities.filter(c => c.promoted && c.promotedTopics && c.promotedTopics.split(',').includes(topic.slug));
  // const promotedCommunities = communities.filter(c => c.promoted);
  const promotedCommunities = communities.filter(c => c.promoted && c.promotedTopics && c.promotedTopics.split(',').includes(topic.slug));

  // const promotedCommunities = platformCommunities.filter(c => c.promoted && c.promotedTopics && c.promotedTopics.split(',').includes(topic.slug));
  const [sizeFilters, setSizeFilters] = useState([]);
  const [featureFilters, setFeatureFilters] = useState([]);
  const sortOptions = ["Popular", "Ratings", "Largest", "Newest"];
  const [sort, setSort] = useState(sortOptions[0]);

  // make the SEO title
  var title = '';
  if (count > 1){
    title += count
  }
  if (title !== ''){title += ' '}
  title += `Best ${topic.name} ${platform.groupNamePlural} to join in 2024`;

  // base it to the topic only
  // const baseUrl = `/topics/${topic.slug}/`

  // sizes of communities
  var sizes = ["Tiny", "Small", "Medium", "Large", "Huge", "Massive", "Gigantic", "Unknown"].map(s => {
    return {
      'name': s,
      'count': platformCommunities.filter(c => c.memberSize === s).length
    }
  });

  // // get count of communities by features
  const features = data.allFeatures.nodes.map(f => {
    f['count'] = platformCommunities.filter(c => c[f.slug]).length;  // we're overwriting total count for the feature here
    return f;
  }).sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0)); 

  // // get count of communities by platform
  const platforms = data.allPlatforms.nodes.map(p => {
    p['count'] = communities.filter(c => c.platform === p.slug).length;  // we're overwriting total count for the platform here
    return p;
  }).sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0)); 

  // filter communiites based on filter
  var filteredCommunities = platformCommunities;
  if (sizeFilters.length){
    filteredCommunities = filteredCommunities.filter(c => sizeFilters.map(f => f.name).includes(c.memberSize))
  }
  if (featureFilters.length){
    filteredCommunities = filteredCommunities.filter(c => !featureFilters.map(f => c[f.slug]).includes(false))
  }

  // sort them
  filteredCommunities = sortCommunities(sort, filteredCommunities)

  // put promoted communities on the top, then the sorted ones
  filteredCommunities = [
    ...sortCommunities("Ratings", promotedCommunities.map(c => Object.assign(c, {showPromotedTag: true}))),
    ...filteredCommunities.filter(c => promotedCommunities.map(p => p.slug).indexOf(c.slug) === -1),
  ]

  var description = `Discover${count ? ' ' + count + ' ' : ' '}${topic.name} communities on ${platform.name}`;
  if (filteredCommunities.length > 0){
    description += ', like '
    description += filteredCommunities.slice(0, 3).map(c => c.name).join(', ')
    description += ' and more.'
  } else {
    description += '.'
  }

  // dates for schema
  const datedCommunities = platformCommunities.filter(c => c.updated).sort((a,b) => (a.updated > b.updated) ? 1 : ((b.updated > a.updated) ? -1 : 0));
  const createdDate = datedCommunities.length ? (new Date(datedCommunities[0].updated)).toISOString().split('T')[0] : null;
  const updatedDate = datedCommunities.length ? (new Date(datedCommunities[datedCommunities.length - 1].updated)).toISOString().split('T')[0] : null;

  // promo sidebar
  // const showPromotionSidebar = !promotedCommunities.length && (topic.mentorcruiseUrl || relatedTopics.length);
  // const showPromotionSidebar = (topic.mentorcruiseUrl || relatedTopics.length);
  const showPromotionSidebar = true;

  return (
    <Layout pageTitle={`${platform.groupNamePlural} for ${topic.people || topic.name}`} shareButtons={platformCommunities.length > 0}
      noBackground={true} enableShare={true}
    >
      <SEO title={title} description={description} imageUrl={topic.image} dontIndex={platform.dontIndexTopicPage ? true : false}
        schema={
          {
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": title,
            "datePublished": createdDate,
            "dateModified": updatedDate,
            "description": description,
            "url": `https://thehiveindex.com/topics/${topic.slug}/platform/${platform.slug}/`,
            "isPartOf": {
              "@type": "WebSite",
              "name": "Hive Index",
              "url": "https://thehiveindex.com/"
            },
            "provider": {
              "@type": "Organization",
              "@id": "Organization",
              "name": "Hive Index"
            },
            "mainEntity": [
              {
                "@type": "DataCatalog",
                "name": title,
                "description": description,
                "dataset": filteredCommunities.slice(0, 20).map((c) => {
                  return {
                    "@type": "Dataset",
                    "name": c.name,
                    "creator": {
                      "@id": "Organization"
                    },
                    "description": `${c.name} is an online community${c.platform ? ` on ${c.platform}` : ``} for ${c.topics.split(',').join(', ')}. ${c.description}`,
                    "url": `https://thehiveindex.com/communities/${c.slug}/`,
                    "image": c.logo ? {
                        "@type": "ImageObject",
                        "url": c.logo,
                        "contentUrl": c.logo
                    } : null,
                    "isPartOf": `https://thehiveindex.com/topics/${topic.slug}/platform/${platform.slug}/`,
                    "license": ["https://thehiveindex.com/privacy-policy/"],
                    "includedInDataCatalog": 
                      {
                        "@type": "DataCatalog",
                        "name": title,
                        "url": `https://thehiveindex.com/topics/${topic.slug}/platform/${platform.slug}/`,
                      }
                    }
                  }
                )
              }
            ]
          }
        }
      />

      {/* Main 3 column grid */}
      <div className={`grid grid-cols-1 gap-4 items-start ${showPromotionSidebar ? 'lg:grid-cols-4' : 'lg:grid-cols-3'} lg:gap-8`}>
        {/* Left column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-3">

          {/* main panel */}
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">

              <div className="lg:col-span-12">
                

                {/*{count < 5 ? (
                  <div className="p-4 sm:p-6">
                    <div className="relative">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-200" />
                      </div>
                      <div className="relative flex items-center justify-between">
                        <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                          About <Link to={`/topics/${topic.slug}/`} className="border-b-2 border-rose-400 hover:border-rose-500">{topic.name}</Link> communities on <Link to={`/platforms/${platform.slug}/`} className="border-b-2 border-rose-400 hover:border-rose-500">{platform.name}</Link>
                        </h2>
                      </div>
                    </div>

                    {topic.description ? (
                      <p className="mt-1 text-gray-500 leading-snug">
                        {topic.description}
                      </p>
                    ) : (
                      <p className="mt-1 text-gray-500 leading-snug">
                        This list of online communities is dedicated to discussing {topic.name}.<br/>
                      </p>
                    )}
                  </div>
                ) : ''}*/}

                
                {/*{platform.description ? (
                  <div className="p-4 sm:p-6">
                    <div className="relative">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-200" />
                      </div>
                      <div className="relative flex items-center justify-between">
                        <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                          About Online Communities on <Link to={`/platforms/${platform.slug}/`}>{platform.name}</Link>
                        </h2>
                      </div>
                    </div>

                    <p className="mt-1 text-gray-500 leading-snug">
                      {platform.description}
                    </p>
                  </div>
                ) : ''}*/}

                {/*{promotedCommunities.filter(c => c.platform === platform.slug).length > 0 ? (
                  <div className="p-4 sm:p-6">
                    <PromotedTopicCommunities promotedCommunities={promotedCommunities.filter(c => c.platform === platform.slug)} topic={topic} />
                  </div>
                ) : ''}*/}

                <div className="p-4 sm:p-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex items-center justify-between">
                      <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                        <Link to={`/topics/${topic.slug}/`} className="border-b-2 border-rose-400 hover:border-rose-500">{topic.name} communities</Link> on <Link to={`/platforms/${platform.slug}/`} className="border-b-2 border-rose-400 hover:border-rose-500">{platform.name}</Link>
                      </h2>
                      <span className="font-semibold text-xs text-gray-500 bg-white px-1 pr-2 mr-auto">{count}</span>
                      
                      {updatedDate ? (
                        <div className="ml-auto text-sm bg-white text-gray-500 pl-3">Updated <b>{updatedDate}</b></div>
                      ) : ''}
                      {/*<Link to="/submit/" className="ml-auto">
                        <button
                          type="button"
                          className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                        >
                          <PlusSmIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <span>New</span>
                        </button>
                      </Link>*/}
                    </div>
                  </div>

                  <div className="flex items-center mt-2">
                    {(platforms.length > 0) ? (
                      <div className="mt-0 mb-2">
                        <TopicPlatformIcons platforms={platforms} topic={topic} currentPlatform={platform} totalCount={communities.length} />
                      </div>
                    ) : ''}

                    {count >= 2 ? (
                      <div className="ml-auto">
                        <TopicFilters
                          // topic={topic} platforms={platforms}
                          features={features} featureFilters={featureFilters} setFeatureFilters={setFeatureFilters}
                          // tags={tags} tagFilter={tagFilter} setTagFilter={setTagFilter}
                          sizes={sizes} sizeFilters={sizeFilters} setSizeFilters={setSizeFilters}
                          sort={sort} setSort={setSort} sortOptions={sortOptions}
                        />
                      </div>
                    ) : ''}
                  </div>

                  {count > 0 ? (
                    <div className="mt-1">
                      <CommunitiesTable communities={filteredCommunities} showPlatform={true} linkModal={true} showRankings={true} />
                    </div>
                  ) : (
                    <div className="mt-1">
                      <div style={{padding: '20px 0 0', textAlign: 'center', fontWeight: '500', color: '#95a5a6'}}>
                        There are no {platform.groupNamePlural} on this topic yet. <br/><br/>
                        Do you know one? Please <Link to="/submit/">submit it</Link>!
                      </div>
                    </div>
                  )}
                </div>

                {otherCommunities.length > 0 ? (
                  <div className="p-4 sm:p-6">
                    <div className="relative">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-200" />
                      </div>
                      <div className="relative flex items-center justify-between">
                        <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                          All {topic.name} communities
                        </h2>
                      </div>
                    </div>

                    <div className="mt-1 text-gray-500">
                      <TopicList topics={[topic]} cols={3} mini={true} />
                      <div className="mt-2">{topic.description}</div>
                    </div>
                  </div>
                ) : ''}
              </div>
            </div>
          </div>
        </div>

        {/* Right column */}
        {showPromotionSidebar ? (
          <div className="grid grid-cols-1 gap-4">

            {relatedTopics && relatedTopics.length ? (
              <RelatedTopics relatedTopics={relatedTopics} topic={topic} />
            ) : ''}

            {deals && deals.length ? (
              <Deals topic={topic} deals={deals} />
            ) : ''}

            {topic.views && topic.views > 100 ? (
              <TopicStats topic={topic} communities={filteredCommunities} />
            ) : ''}

            

            {/*{false && topic.showAds ? (
              <div className="rounded-lg bg-white overflow-hidden shadow p-6">
                <Adsense id="1964429498" type="topic" />
              </div>
            ) : ''}*/}

          </div>
        ) : ''}
      </div>
    </Layout>
  )
}

export const topicPageQuery = graphql`
  query PlatformTopicPage($slug: String, $communitySlugs: [String], $platformSlug: String, $relatedTopicSlugs: [String]) {
    site {
      siteMetadata {
        title
      }
    }
    topic: googleSpreadsheetSourceTopics(slug: {eq: $slug}) {
      slug
      name
      isRoot
      count
      people
      image
      description
      mentorcruiseUrl
      showAds
      added
      updated
      category
      visitors
      views
    }
    platform: googleSpreadsheetSourcePlatforms(slug: {eq: $platformSlug}) {
      name
      slug
      count
      logoBig
      groupName
      groupNamePlural
      description
      dontIndexTopicPage
    }
    deals: allGoogleSpreadsheetSourceDeals(filter: {
      topic: {eq: $slug},
      active: {eq: "TRUE"}
    }) {
      nodes {
        order
        title
        subtitle
        topic
        offer
        logo
        url
      }
    }
    relatedTopics: allGoogleSpreadsheetSourceTopics(filter: {slug: {in: $relatedTopicSlugs}}) {
      nodes {
        slug
        name
        isRoot
        count
        people
        image
        description
        visitors
        views
      }
    }
    communities: allGoogleSpreadsheetSourceCommunities(filter: {slug: {in: $communitySlugs}, removed: {ne: true}}, sort: {fields: order, order: ASC}) {
      totalCount
      nodes {
        name
        slug
        order
        updated
        countFeatures
        members
        platform
        description
        topics
        logo
        promoted
        sponsored
        gold
        promotedTopics
        memberSize
        forum
        chat
        apply
        established
        pairing
        events
        perks
        jobs
        newsletter
        paid
        courses
        tools
        token
        reviewCount
        reviewAvg
        reviewsPositive
        views
        visitors
        redirects
        claimed
      }
    }
    allFeatures: allGoogleSpreadsheetSourceFeatures(sort: {fields: name, order: ASC}) {
      nodes {
        slug
        name
        icon
      }
    }
    allPlatforms: allGoogleSpreadsheetSourcePlatforms(sort: {fields: name, order: ASC}) {
      nodes {
        slug
        name
        count
        logoBig
        logoSmall
        groupName
        groupNamePlural
        promoted
        descriptionForCommunities
      }
    }
  }
`

export default PlatformTopicPage

